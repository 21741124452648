/** @format */
import { IMAGES } from "../../assets/images";
import "../../assets/style/salman.css";
import {
  Form,
  Button,
  NavDropdown,
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";
const Header = () => {
  return (
    <Navbar className="navbaar-sticky py-3" bg="white" expand="lg">
      <Container className="text-items" fluid>
        <Navbar className="Logo mx-5 py-0" href="#">
          <img
            src={IMAGES.UNIVERSALALLOCATOR}
            className="universal-allocator"
            alt="not"
          />
        </Navbar>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto">
            <Nav.Link href="#section-2" className="nav-tabs-listing">
              About
            </Nav.Link>
            <Nav.Link href="#services-section" className="nav-tabs-listing">
              Services
            </Nav.Link>
            <Nav.Link href="#meet-the-team" className="nav-tabs-listing">
              Meet the Team
            </Nav.Link>
            <Nav.Link href="#contact-id-section" className="nav-tabs-listing">
              Contact Us
            </Nav.Link>
          </Nav>
          {/* <Form className="d-flex mx-5">
            <Button className="Create-account-btn mx-4">Sign In</Button>
            <Button className="two-button  ">Create account</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
