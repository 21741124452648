/** @format */

import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsArrowRightShort } from "react-icons/bs";
import "../../assets/style/salman.css";
import { IMAGES } from "../../assets/images";
const Highquality = () => {
  const highquality = [
    {
      suplimentname:
        "We are a recruitment and CV-sourcing agency that helps businesses find the best candidates",
      price:
        "We are a Recruitment, Headhunting and CV-sourcing agency that helps businesses find the best candidates",
      heading:
        "for their open positions. We have a team of experienced recruiters who are experts in finding and assessing talent.",
      explore: "Explore catalog",
      img: IMAGES.QUALITYSUP,
    },
  ];
  return (
    <section className="Highquality py-5" id="section-2">
      {highquality.map((item, index) => {
        return (
          <Row className="w-100">
            <Row>
              <Col lg={12}>
                <div className="pt-5 pb-2">
                  <h1 className="text-center Integrative-colors mb-5">
                    About Us
                  </h1>
                </div>
              </Col>
            </Row>
            <Col lg={6}>
              <div className="p-5">
                <h1 className="headings-color">{item.price}</h1>
                <p className="Thousands pt-4">{item.heading}</p>
                <h5 className="pt-2 pb-5 Grow-practice">{item.practice}</h5>
                <div
                  className="d-flex pb-5"
                  href="#services-section"
                  role="button"
                >
                  <p className="Practitioner cursor-pointer">{item.explore}</p>
                  <BsArrowRightShort
                    className="arrow-icon cursor-pointer mx-2"
                    href="#services-section"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="p-5">
                <img className="QUALITYSUP pt-4" src={IMAGES.ABOUTUS}></img>
              </div>
            </Col>
          </Row>
        );
      })}
    </section>
  );
};

export default Highquality;
