/** @format */

import React from "react";

import { Col, Row, Card } from "react-bootstrap";
import { HiOutlineMail } from "react-icons/hi";
import { IMAGES } from "../../assets/images";
const Practitioners = () => {
  const practitioners = [
    {
      img: IMAGES.SULEMAN,
      patientaccount: "Sam",
      outcomes: "Recruitment Consultant",
      createacc: "info@universalallocators.com",
      button: "Learn more",
    },
    {
      img: IMAGES.MAHAM,
      patientaccount: "Maham",
      outcomes: "Client Relationship Manager",
      createacc: "Maham@universalallocators.com",
      button: "Learn more",
    },
    {
      img: IMAGES.HARSH,
      patientaccount: "Sid",
      outcomes: "Marketing Manager ",
      createacc: "Maham@universalallocators.com",
      button: "Learn more",
    },
  ];
  return (
    <section className="Practitioners py-5" id="meet-the-team">
      <>
        <Row className="w-100">
          <Col lg={12}>
            <h1 className="text-center month-detail pt-5">Meet the Team</h1>
          </Col>
        </Row>
        <Row className="mx-5">
          {practitioners.map((item, index) => {
            return (
              <Col lg={4}>
                <Card className="mt-4 man-image-card">
                  <img className="p-4" variant="top" src={item.img} />
                  <Card.Body className="">
                    <Card.Title className="treat-card">
                      {item.patientaccount}
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Text className="Grow-practice mb-0">
                        {item.outcomes}
                      </Card.Text>
                      {/* <Button className="cards-button">{item.createacc}</Button> */}
                      <div className="d-flex cursor-poiner">
                        {/* <p className="Practitioner">{item.button}</p> */}
                        <HiOutlineMail
                          className="arrow-icon cursor-poiner email-team-icon mx-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="info@universalallocators.com"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </>
    </section>
  );
};

export default Practitioners;
