/** @format */

import React, { useState } from "react";
import { BsArrowRightShort, BsClipboardData } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { FaHands, FaUsers } from "react-icons/fa";
import { TbSocial } from "react-icons/tb";
import { CgFileDocument } from "react-icons/cg";
import { TbMapSearch } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
const Education = () => {
  const data = [
    {
      icon: <RiTeamFill className="message-icon" />,
      treat: "CANDIDATE SOURCING",
      text1:
        "Candidate sourcing Via Job boards, LinkedIn, and other social networks worldwide.",
      text2: "",
    },

    {
      icon: <IoIosPeople className="message-icon" />,
      treat: "PRE-QUALIFICATION",
      text1:
        "Support in selecting candidates against the vacant posts being available within the line via call.",
      text2: "",
    },

    {
      icon: <FaHands className="message-icon" />,
      treat: "VIRTUAL SUPPORT AND PARTNERSHIP",
      text1:
        "We believe in partnership, to add value in your business by reducing your  per headcount costs.",
      text2: "",
    },
    {
      icon: <MdOutlineEmail className="message-icon" />,
      treat: "EMAIL MARKETING",
      text1:
        "We help businesses to develop sales pipelines via email marketing to make their customers aware of new products, discounts, and other services.",
      text2: "",
    },
    {
      icon: <CgFileDocument className="message-icon" />,
      treat: "INTERVIEW SCREENING",
      text1:
        "Our Experts Determind Whether The Applicant You're Looking is Great Fit For The Specific Role.",
      text2: "",
    },
    {
      icon: <TbMapSearch className="message-icon" />,
      treat: "SEO SERVICES",
      text1:
        "Helping you to boost your online presence to stand in current market trends.",
      text2: "",
    },
    {
      icon: <BsClipboardData className="message-icon" />,
      treat: "DATA ENTRY",
      text1: "Managing and updating raw data into database systems.",
      text2: "",
    },
    {
      icon: <TbSocial className="message-icon" />,
      treat: "CLIENT RELATIONSHIP MANAGEMENT",
      text1:
        "Providing you with a virtual support to manage and prioritizing customer needs and how you can help them.",
      text2: "",
    },
    {
      icon: <FaUsers className="message-icon" />,
      treat: "LEADS GENERATION",
      text1:
        "Helping your business to attract and qualify more potential customers.",
      text2: "",
    },
  ];

  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  console.log(form);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  /* mehkmk9vla0FcIa5H */

  /* service_rlecyyn */

  /* template_nnu5h3l */

  //   service_zocf5jj

  // ID: template_jetfe1h

  // API key: DrMU0_bCjRZdYQ6bp

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_zocf5jj",
        "template_jetfe1h",
        {
          from_name: form.name,
          to_name: "Maham",
          form_email: form.email,
          to_email: "info@universalallocators.com",
          message: form.message,
        },
        "DrMU0_bCjRZdYQ6bp"
      )

      .then(
        () => {
          setLoading(false);
          alert("Thank You. I will get back to you as soon as possible.");
          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
          alert("something went wrong.");
        }
      );
  };

  return (
    <>
      <section id="services-section">
        <Row className="py-5 w-100">
          <Col lg={12}>
            <h1 className="pt-4 text-center Integrative-colors">
              Our Services
            </h1>
          </Col>
          {data.map((item, index) => {
            return (
              <Col lg={6} className="d-flex">
                <div className="d-flex justify-content-center p-5">
                  <span className="message">{item.icon}</span>
                  <div className="px-4">
                    <h3 className="treat-card"> {item.treat}</h3>
                    <p className="Thousands">
                      {item.text1} <br></br> {item.text2}
                    </p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <div className="pt-5">
                <h1 className="text-center Integrative-color pt-3">
                  Our customers are our top priority.<br></br> We strive to
                  incorporate our <br></br> client’s needs with our solutions.
                </h1>
                <p className="text-center Thousands py-4">
                  Tell us what you want to create and we’ll get started on it.
                </p>
                {/* <div className="d-flex justify-content-center">
                <Button className="cards-button px-3 py-2">
                  Create an account
                  <BsArrowRightShort className="account-btn" />
                </Button>
              </div> */}
                <img
                  className="d-flex pt-5 m-auto growth-plant"
                  src={IMAGES.PLANTCIRCLE}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5 px-5 contact-section" id="contact-id-section">
        <Row className="px-3 py-5 d-flex align-items-center">
          <Col lg={6}>
            <h1 className="Integrative-color-contact">Contact Us</h1>
            <div>
              {/* <h4 className="pt-3 treat-card">Practitioner resources</h4> */}
              <p className="Thousands-contact">
                We are here to help you with any questions related to your
                business. How can we assist you today?
              </p>
              <div className="d-flex pb-2">
                <p className="Thousands-contact">
                  Phone: +1 720 316 0070, +44 12 2392 6060
                </p>
                {/* <BsArrowRightShort className="arrow-icon mx-2" /> */}
              </div>
              <div>
                <p className="Thousands-contact">
                  Location: USA, Ireland, UK, PK
                </p>
                <p className="Thousands-contact">
                  EMail: info@universalallocators.com
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <form ref={formRef} onSubmit={handleSubmit}>
              <div class="form-box">
                <div class="header-text">Get in touch</div>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="What's your good name?"
                />
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="What's your Email Address"
                />
                {/* <input placeholder="Phone (optional)" type="number" /> */}
                <textarea
                  name="message"
                  value={form.message}
                  onChange={handleChange}
                  placeholder="What you want to say?"
                  className="mt-3 w-100 rounded font-monospace px-2 textarea-box"
                />
                <button className="Submit-button" type="submit">
                  {loading ? "sending" : "send"}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </section>
    </>
  );
};
export default Education;
