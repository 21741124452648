/** @format */

import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import "../../assets/style/salman.css";
import { IMAGES } from "../../assets/images";
import Slider from "react-slick";

const Plus = () => {
  // const images = [
  //   {
  //     images: IMAGES.DRCHORONO,
  //   },
  //   {
  //     images: IMAGES.JANELOGO,
  //   },
  //   {
  //     images: IMAGES.HEALTHLINE,
  //   },
  //   {
  //     images: IMAGES.BETTERLOGO,
  //   },
  //   {
  //     images: IMAGES.CERBOLOGO,
  //   },
  //   {
  //     images: IMAGES.POWERPRACTICE,
  //   },
  //   {
  //     images: IMAGES.DRCHORONO,
  //   },
  //   {
  //     images: IMAGES.JANELOGO,
  //   },
  //   {
  //     images: IMAGES.HEALTHLINE,
  //   },
  //   {
  //     images: IMAGES.BETTERLOGO,
  //   },
  //   {
  //     images: IMAGES.CERBOLOGO,
  //   },
  //   {
  //     images: IMAGES.POWERPRACTICE,
  //   },
  // ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col>
            <div className="pt-5 pb-2">
              <h1 className="text-center Integrative-color mb-5">
                We leverage your KPI by our services. <br></br>Mainly Focusing
              </h1>
            </div>
          </Col>
        </Row>
        <div>
          <Slider {...settings}>
            <div className="d-flex justify-content-center">
              <img src={IMAGES.LINKEDIN} className="w-100 linkedIn-icon" />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 opengb-icon" src={IMAGES.OPENGRAP} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 indeed-icon" src={IMAGES.INDEED} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 round-icon" src={IMAGES.ROUND} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 husspot-icon" src={IMAGES.HUBSPOT} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 new-zapier" src={IMAGES.ZAPIER} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 cv-library" src={IMAGES.CVLIBRAYRY} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 zip-Recruiter" src={IMAGES.ZIPRECRUITER} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 mailchimp" src={IMAGES.MAILCHIMP} />
            </div>
            <div className="d-flex justify-content-center">
              <img className="w-100 glassdoor" src={IMAGES.GLASSDOOR} />
            </div>
          </Slider>
        </div>
      </Container>
    </>
  );
};

export default Plus;
