/** @format */

import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
const Wellness = () => {
  return (
    <section className="pt-5">
      <Container>
        <Row>
          <Col>
            <div className="pt-5">
              <h1 className="text-center Integrative-color pt-3">
                Our customers are our top priority.<br></br> We strive to
                incorporate our <br></br> client’s needs with our solutions.
              </h1>
              <p className="text-center Thousands py-4">
                Tell us what you want to create and we’ll get started on it.
              </p>
              {/* <div className="d-flex justify-content-center">
                <Button className="cards-button px-3 py-2">
                  Create an account
                  <BsArrowRightShort className="account-btn" />
                </Button>
              </div> */}
              <img
                className="d-flex pt-5 m-auto growth-plant"
                src={IMAGES.PLANTCIRCLE}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Wellness;
