/** @format */

import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { RiMentalHealthLine } from "react-icons/ri";
import { GiRollingEnergy, GiTruck } from "react-icons/gi";
import { ImBooks } from "react-icons/im";
import { FaUsers, FaSuitcase } from "react-icons/fa";
import { VscLaw } from "react-icons/vsc";
import { IoIosConstruct } from "react-icons/io";
import { MdEngineering, MdOutlinePrecisionManufacturing } from "react-icons/md";
const Essentialservice = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data = [
    {
      icon: <RiMentalHealthLine className="message-icon" />,
      treat: "HEALTH CARE",
      text1:
        "Candidate sourcing Via Job boards, LinkedIn, and other social networks worldwide.",
      text2: "",
    },

    {
      icon: <ImBooks className="message-icon" />,
      treat: "EDUCATION",
      text1:
        "Support in selecting candidates against the vacant posts being available within the line via call.",
      text2: "",
    },

    {
      icon: <MdEngineering className="message-icon" />,
      treat: "ENGINEERING",
      text1:
        "We believe in partnership, to add value in your business by reducing your  per headcount costs.",
      text2: "",
    },
    {
      icon: <MdOutlinePrecisionManufacturing className="message-icon" />,
      treat: "MANUFACTURING",
      text1:
        "Our Experts Determind Whether The Applicant You're Looking is Great Fit For The Specific Role.",
      text2: "",
    },
    {
      icon: <GiRollingEnergy className="message-icon" />,
      treat: "ENGERGY AND UTILITIES",
      text1:
        "Helping you to boost your online presence to stand in current market trends.",
      text2: "",
    },
    {
      icon: <IoIosConstruct className="message-icon" />,
      treat: "CONSTRUCTION",
      text1: "Managing and updating raw data into database systems.",
      text2: "",
    },
    {
      icon: <FaUsers className="message-icon" />,
      treat: "HUMAN RESOURCE",
      text1:
        "Providing you with a virtual support to manage and prioritizing customer needs and how you can help them.",
      text2: "",
    },
    {
      icon: <FaSuitcase className="message-icon" />,
      treat: "BANKING AND FINANCE",
      text1:
        "Helping your business to attract and qualify more potential customers.",
      text2: "",
    },
    {
      icon: <GiTruck className="message-icon" />,
      treat: "TRANSPORT AND LOGISTICS",
      text1:
        "Helping your business to attract and qualify more potential customers.",
      text2: "",
    },
    {
      icon: <VscLaw className="message-icon" />,
      treat: "LAW",
      text1:
        "Helping your business to attract and qualify more potential customers.",
      text2: "",
    },
  ];
  return (
    <>
      {/* <div className="carousel-cards py-5"> */}
      <section id="services-section">
        <Row className="py-5 w-100">
          <Col lg={12}>
            <h1 className="pt-4 text-center Integrative-colors">KEY SECTORS</h1>
          </Col>
          {data.map((item, index) => {
            return (
              <Col lg={6} className="d-flex justify-content-around">
                <div className="justify-content-center align-items-center key-sector p-5">
                  <span className="message mb-3">{item.icon}</span>
                  <div className="px-4">
                    <h3 className="treat-card d-flex align-items-center mb-0">
                      {item.treat}
                    </h3>
                    {/* <p className="Thousands">
                      {item.text1} <br></br> {item.text2}
                    </p> */}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </section>
      {/* </div> */}
    </>
  );
};

export default Essentialservice;
