/** @format */

import React from "react";
import {
  BsInstagram,
  BsFacebook,
  BsYoutube,
  BsLinkedin,
  BsPinterest,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";

import { Col, Row } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
const Footer = () => {
  return (
    <section className="footer px-5 py-3">
      <Row className="px-3 d-flex justify-content-center align-items-center flex-wrap">
        <Col
          lg={6}
          md={6}
          sm={12}
          className="d-flex align-items-center footer-section-nav"
        >
          <div className="">
            <img className="footer-Logo" src={IMAGES.UNIVERSALFOOTER}></img>
          </div>
          <span className="universal-allocator-text">Universal Allocators</span>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          className="d-flex justify-content-end align-items-center footer-section-nav"
        >
          <div className="d-flex justify-content-start">
            <BsInstagram className="icon-bundle mx-3 cursor-pointer" />
            {/* <BsFacebook className="icon-bundle mx-3 cursor-pointer" /> */}
            {/* <BsYoutube className="icon-bundle mx-3 cursor-pointer" /> */}
            <a href="https://web.facebook.com/profile.php?id=61550197394477">
              <BsFacebook className="icon-bundle mx-3 cursor-pointer" />
            </a>
            <a href="https://www.linkedin.com/company/universalallocators/">
              <BsLinkedin className="icon-bundle mx-3 cursor-pointer" />
            </a>
            {/* <BsPinterest className="icon-bundle mx-3 cursor-pointer" /> */}
            <AiFillTwitterCircle className="icon-bundle mx-3 cursor-pointer" />
          </div>
        </Col>
        {/* <Col lg={6} className="d-flex pt-5">
          <div className="ideal-footer">
            <h4>PRODUCT</h4>
            <ul>
              <li>Practitioner software</li>
              <li>Integrations</li>
              <li>Pricing</li>
              <li>Patients</li>
              <li>Supplement quality</li>
              <li>Treatment adherence</li>
              <li>Catalog</li>
              <li>Wholesale</li>
            </ul>
          </div>

          <div className="ideal-footer">
            <h4>COMPANY</h4>
            <ul>
              <li>Spotlight</li>
              <li>About Fullscript</li>
              <li>Collective</li>
              <li>Leadership</li>
              <li>Culture guide</li>
              <li>Careers</li>
              <li>Engineering</li>
              <li>News</li>
            </ul>
          </div>
          <div className="ideal-footer">
            <h4>SUPPORT</h4>
            <ul>
              <li>Practitioner support</li>
              <li>Practitioner support</li>
              <li>Patient help</li>
              <li>Testimonials</li>
              <li>General FAQ</li>
              <li>Patient shipping</li>
              <li>Wholesale shipping</li>
            </ul>
          </div>
        </Col> */}
      </Row>
    </section>
  );
};

export default Footer;
