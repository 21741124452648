/** @format */

import React from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import { MdContactPhone } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { IMAGES } from "../../assets/images";
import "../../assets/style/salman.css";
const Simplify = () => {
  const threecard = [
    {
      id: 0,
      background: "#FF6263",
      heading: "Personalized care",
      paragraph:
        "Custom protocols and features to personalize any treatment plan.",
      imge: IMAGES.PERSONALIZE,
    },

    {
      id: 1,
      background: `#FF9373`,
      heading: "Treatment adherence",
      paragraph:
        " Tools, education, and reminders to help support behavior change.",
      imge: IMAGES.TREATMENT,
    },

    {
      id: 2,
      heading: "Supplement support",
      paragraph:
        "World-class wellness products with virtual dispensing or wholesale ordering.",
      imge: IMAGES.SUPLIMENTSPORT,
    },
  ];

  return (
    <>
      <section className="section-one py-5 px-5">
        <Row className="my-4">
          <Col lg={12} sm={12} xl={6} className="css-typing">
            {/* <h4 className="Full-script">Fullscript-</h4> */}
            <h1 className="Full-script Simplify full-screen">
              "The Future of Work Starts Here"
            </h1>
            <div>
              <h1 className="Full-script Simplify small-screen">
                "The Future of Work
              </h1>
              <h1 className="Full-script Simplify small-screen tex-half">
                Starts Here"
              </h1>
            </div>
            <p className="patients-thrive pt-4">
              Delivering the Best Recruitment Services.
            </p>

            <div className="d-flex mt-5">
              <Button
                className="cards-button button-85"
                href="#services-section"
              >
                Our Services
              </Button>
            </div>
          </Col>

          <Col md={6} sm={12} className="hide-animate">
            <div className="images-animation2">
              <Row>
                <Col md={6}>
                  <div className="d">
                    <Card className="all-card-color design-card4">
                      <div className="d-grid justify-content-center">
                        {/* <Card.Img
                          variant="center"
                          className="py-3 pr-2 dr-helis"
                          src={IMAGES.EMAILICON}
                        /> */}
                        <span className="d-flex align-items-center icon-boundries my-3">
                          <FiMail className="animate-icons" />
                        </span>
                      </div>
                      <p className="px-3 set-cards-text text-center">
                        Email Marketing
                      </p>
                    </Card>
                  </div>
                </Col>
                <Col md={6}>
                  <Card className="all-card-color design-card5">
                    <div className="d-flex justify-content-center ">
                      {/* <Card.Img
                        variant="center"
                        className="py-3 pr-2"
                        src={IMAGES.NOTIFICATION}
                      /> */}
                      <span className="d-flex align-items-center icon-boundries my-3">
                        <MdContactPhone className="animate-icons" />
                      </span>

                      <p className="py-3 px-3 set-cards-text align-self-center mb-0">
                        Virtual Assistent
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Card className="all-card-color design-card6">
                    <div className="d-grid justify-content-center">
                      {/* <Card.Img
                        variant="center"
                        className="py-3 pr-2 dr-helis"
                        src={IMAGES.DRCORTES}
                      /> */}
                      <span className="d-flex align-items-center icon-boundries my-3">
                        <CgFileDocument className="animate-icons" />
                      </span>
                    </div>
                    <p className="px-3 set-cards-text text-center">
                      CV Sourcing
                    </p>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      {/* <section className="p-5">
        <Row>
          <Col>
            <h2 className="Integrative-color">
              Financial services, Engineering, FMCG, Sales,
            </h2>
            <h2 className="headings-color">
              Marketing etc. We strive to achieve its clients’ success which
              ultimately brings a reward mutually shared.
            </h2>
            <p className="py-3 Thousands">
              Thousands of practitioners use Fullscript to simplify their
              workflow,<br></br> grow their practice, and yield better patient
              outcomes.
            </p>
          </Col>
        </Row>
        <Row className="py-5">
          {threecard.map((item, index) => {
            return (
              <Col lg={4} key={index}>
                <Card
                  //   style={{ background: item.background }}
                  className={`Personalized-card pt-4 pb-0 ${
                    item.id === 0
                      ? "Personalized-card"
                      : item.id === 1
                      ? "Personalized-card1"
                      : item.id === 2
                      ? "Personalized-card2"
                      : ""
                  }`}
                >
                  <Card.Body>
                    <Card.Title className="treat-card">
                      {item.heading}
                    </Card.Title>
                    <Card.Text className="Thousands">
                      {item.paragraph}
                    </Card.Text>
                    <Card.Img className="px-4" variant="top" src={item.imge} />
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </section> */}
    </>
  );
};

export default Simplify;
