import fullscript from "./fullscript-logo-1.svg";
import homepage1 from "../images/homepage-person-1.jpeg";
import homepage2 from "./homepage-person-2.jpeg";
import supliment from "./supplement-icon.svg";
import drhelis from "./dr-helias-notification.png";
import shipping from "./shipping-icon.svg";
import personalize from "./personalized-care.png";
import treatment from "./treatment-adherence.png";
import suplimentsport from "./supplement-support.png";
import qualitysupliment from "./Quality-supplements-US.png";
import drchorono from "./DrChrono-logo-1-300x161.png";
import janelogo from "./Jane-logo-1-300x165.png";
import healthline from "./Healthie-logo-1-300x165.png";
import betterlogo from "./Better-logo-1-300x165.png";
import cerbologo from "./Cerbo-logo-300x161.png";
import powerpractice from "./Power2practice-logo-300x161.png";
import homepricing from "./home-pricing-1.jpg";
import pricing from "./home-pricing-2.jpg";
import knowledge from "./home-knowledge-center-1.jpeg";
import commitment from "./commitment-support.svg";
import chris from "./Chris_Oswald.png";
import robin from "./robin-berzin-md.png";
import meghan from "./Meghan_Walker.png";
import jeffry from "./jeffrey-bland-phd.png";
import quote from "./testimonial-quote-white.svg";
import plantcircle from "./plant-circle-crop.svg";
import whitelogo from "./fullscript-logo-white.svg";
import emailicon from "./email-icon.svg";
import notification from "./notification-icon.svg";
import drcortes from "./dr-cortes-notification.png";
import universalAllocator from "./universal-allocator.JPG";
import aboutUS from "./aboutUS.jpg";
import christian from "./christian.jpg";
import maham from "./maham.jpg";
import universalFooter from "./universal-footer.png";
import ZipRecruiter from "./Zip-recruiter.png";
import opengraphlogo from "./opengraph-logo.png";
import indeed from "./indeed.png";
import round from "./round.png";
import hubspot from "./hubspot-crm.png";
import zapier from "./zapier.png";
import cvlibrary from "./cvlibrary.png";
import Mailchimp from "./Mailchimp.jpg";
import GlassDoor from "./Glass-door.png";
import linkedIn from "./linkedIn.png";
import harsh from "./harsh.jpg";

export const IMAGES = {
  HARSH: harsh,
  LINKEDIN: linkedIn,
  GLASSDOOR: GlassDoor,
  MAILCHIMP: Mailchimp,
  CVLIBRAYRY: cvlibrary,
  ZAPIER: zapier,
  HUBSPOT: hubspot,
  ROUND: round,
  INDEED: indeed,
  OPENGRAP: opengraphlogo,
  ZIPRECRUITER: ZipRecruiter,
  UNIVERSALFOOTER: universalFooter,
  MAHAM: maham,
  SULEMAN: christian,
  ABOUTUS: aboutUS,
  UNIVERSALALLOCATOR: universalAllocator,
  FULLSCRIPR: fullscript,
  HOMEPAGE1: homepage1,
  HOMEPAGE2: homepage2,
  SUPLIMENT: supliment,
  DRHELIS: drhelis,
  SHIPPING: shipping,
  PERSONALIZE: personalize,
  TREATMENT: treatment,
  SUPLIMENTSPORT: suplimentsport,
  QUALITYSUP: qualitysupliment,
  DRCHORONO: drchorono,
  JANELOGO: janelogo,
  HEALTHLINE: healthline,
  BETTERLOGO: betterlogo,
  CERBOLOGO: cerbologo,
  POWERPRACTICE: powerpractice,
  HOMEPRICING: homepricing,
  PRICING: pricing,
  KNOWLEDGE: knowledge,
  COMMITMENT: commitment,
  CHRIS: chris,
  ROBIN: robin,
  MEGHAN: meghan,
  JEFFRY: jeffry,
  QOUTE: quote,
  PLANTCIRCLE: plantcircle,
  WHITELOGO: whitelogo,
  EMAILICON: emailicon,
  NOTIFICATION: notification,
  DRCORTES: drcortes,
};
